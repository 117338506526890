<template>
<div>
    <div class="barra"></div>
    <userSession />
    <div class="row justify-content-md-center">
        <div class="col">
            <h1 class="text-center mt-4 text-title">
                Cursos
            </h1>
        </div>
    </div>
    <div v-if="loadingCourses">
        <loadingQuiz/>
    </div>
    <div class="row" v-else>
        <div class="col-12 col-sm-12 col-md-6" v-for="course in courses" :key="course.id">
            <router-link :to="{ name: 'themas', params: { url: course.slug } }" :title="course.name">
                <div class="row dcourse">
                    <div class="course_avatar">
                        <div class="cAvatar">
                        <img
                            :src="appDomainImg + 'courses/' + course.avatar" :alt="course.avatar"
                            class="cImage"
                        />
                        </div>
                    </div>
                    <div class="col course_detail">
                        <p class="course_title text-break">Curso de</p>
                        <p class="course_name text-break">{{ course.name }}</p>
                        <p class="course_themes text-break">{{ course.teams_count }} temas</p>
                    </div>
                </div>
            </router-link>
        </div>
        <br />
    </div>
</div>
</template>

<script>
import {
    mapState
} from 'vuex'
import loadingQuiz from "@/views/student/constants/loading/courses.vue";
import userSession from "@/components/student/notifications/userSession/index.vue";

export default {
    name: 'Courses',
    components: {
        loadingQuiz,
        userSession
    },
    async created() {
        await this.$store.dispatch('setBlock')
        await this.$store.dispatch('setStudent')
        await this.$store.dispatch('setCourses')
        await this.startInvite()
    },
    methods: {
        startInvite(){
            const linkVersus  = this.$store.state.linkVersus
            if(linkVersus && this.$store.state.linkVersusStatus){
                this.$router.push({
                  name: "Invite",
                  params: { url: this.linkVersus },
                });
            }
        },
    },
    computed: {
        ...mapState([
            'block',
            'courses',
            'loadingCourses',
            'linkVersus',
        ])
    }
}
</script>

<style scoped>
.barra {
  height: 100px;
}
.course_avatar{
    display: flex;
    align-content: center;
    justify-content: center;
    margin-left: 15px;
}
a,
a:hover,
a:visited,
a:active,
a:link {
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}
.text-title{
    font-family: 'Ubuntu', Helvetica, Arial;
     font-size: 50px;
}
.course_title {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    line-height: 0.5;
    color: #9EA9A7;
}
.course_name {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    line-height: .9;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #202020;
}
.course_themes{
    /*text-align: right;*/
    margin-right: 20px;
    color: #9EA9A7;
}

.dcourse:hover {
    transition-duration: 100ms;
    box-shadow: 5px 5px 15px -3px #76767687;
}

.dcourse {
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    /*background: #E8F0FF;*/
    margin-top: 10px;
    margin-left: 0.1px;
    margin-right: 0.1px;
    margin-bottom: 10px;
    background: white;

}

.course_detail {
    padding-top: 20px;
    padding-right: 5px;
    margin: -4px;
    margin-left: 30px;
    padding: 0px;
    border: 0px;
    color: #1D2B8D;
    margin-top: 8px;
}

.icirculo {
    margin: 7px 0px 0px 17px;
    height: 80%;
    align-items: center;
    padding: 5px;
}

.cCirculo {
    width: 75px;
    height: 75px;
    background: white;
    border-radius: 50%;
    margin: 0px -10px 0px 20px;
    overflow: hidden;
    align-items: center;
    justify-items: center;
    display: grid;
    top: 5px;
}

</style>
