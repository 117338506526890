<template>
  <div>
      <Courses />
  </div>
</template>
<script>
import Courses from '@/components/student/courses/index.vue'

export default {
  name: 'CourseIndex',
  components: {
    Courses,
  },
  data () {
    return {
    }
  },
  created () {
  }
}
</script>
